import apiClient from '../../../api-config/axios.config';
import { handleInterceptor } from '../../../utilities/handleHTTPInterceptor';

const loginFromRedirect = (token, nc = false) => {
  return apiClient
    .post('client/express/loginWithRequest', { payment_id: token, nc })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'loginFromRedirect failed');
    });
};

const requestSubdomainRetailer = (params) => {
  return apiClient
    .get('v2/auth/retailers/subdomain-retailer', { params })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'requestSubdomainRetailer failed');
    });
};

const checkIfNewUser = () => {
  return apiClient
    .get('client/is-new-user')
    .then((response) => {
      return response.data.data.message;
    })
    .catch((error) => {
      return handleInterceptor(error, 'checkIfNewUser failed');
    });
};

const setNotNewUser = () => {
  return apiClient
    .post('client/clear-is-new-user')
    .then((response) => {
      return response.data.data.message;
    })
    .catch((error) => {
      return handleInterceptor(error, 'setNotNewUser failed');
    });
};

const logToServer = (message, severity) => {
  console.debug(message);
  apiClient
    .post('client/log/SvG2PpoeBm2ijy0u', { message, severity })
    .catch(() => {
      console.log('logging failed');
    });
};

const enableMacLogin = (data) => {
  return apiClient
    .post('client/enable-mac-login', data)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'enableMacLogin failed');
    });
};

export {
  requestSubdomainRetailer,
  loginFromRedirect,
  checkIfNewUser,
  setNotNewUser,
  logToServer,
  enableMacLogin,
};
